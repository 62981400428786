import * as React from "react";
import { FaqProps } from "./typings";
import { observer, inject } from "mobx-react";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { UitkExpandoList, UitkExpandoListItem, UitkExpandoPeek } from "@egds/react-core/expando";
import { UitkSpacing } from "@egds/react-core/spacing";
import {
  FlexTrackingInfo,
  Action,
  sendDelayedClickstreamTrackEvent,
} from "src/components/utility/analytics/FlexAnalyticsUtils";
import { SchemaOrgScript } from "components/utility/SchemaOrg/SchemaOrgScript";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkHeading } from "@egds/react-core/text";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { FrequentlyAskedQuestionsFlexModuleResult } from "typings/microserviceModels/frequently-asked-questions-flex-module";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { getFmId } from "src/stores/ExperienceTemplateStore";

/**
 * Legacy Frequently asked questions module that allows users to gather
 * more information on their desired destinations
 */
const LegacyFaq = (props: FaqProps) => {
  const { templateComponent, markdown, flexModuleModelStore, context } = props;
  const { formatText } = useLocalization();
  const track = useClickTracker();
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  if (!templateComponent || !markdown || !flexModuleModelStore) {
    return null;
  }

  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const fmId = getFmId(templateComponent);
  const model = flexModuleModelStore.getModel(id) as FrequentlyAskedQuestionsFlexModuleResult | null;

  if (!model) {
    return null;
  }

  const { faqs, displayThreshold, isExpando, structuredData, contentId } = model;

  if (!faqs || faqs.length === 0) {
    return null;
  }
  const itemKeyHelper = new ItemKeyHelper("faq");

  const initialDisplayThreshold = displayThreshold || faqs?.length;
  const [numberOfFaqsToShow, setNumberOfFaqsToShow] = React.useState(initialDisplayThreshold);
  const [isExpandoVisible, setIsExpandoVisible] = React.useState(false);
  const clickHandler = () => {
    const toSet = numberOfFaqsToShow === initialDisplayThreshold ? faqs.length : initialDisplayThreshold;
    setNumberOfFaqsToShow(toSet);
    setIsCollapsed(!isCollapsed);
  };

  const faqsCompiled = faqs.map((faq) => {
    return {
      ...faq,
      answer: markdown.compileMarkdown(faq.question, faq.answer),
    };
  });

  const handleClickAction = (): void => {
    setIsExpandoVisible(!isExpandoVisible);
  };
  const analytics = {
    id: "FAQ.action.Expando",
    description: "FAQ.action.SeeMore_SeeLess",
    callback: (referrerId: string, linkName: string): void => {
      track(referrerId, linkName);
    },
  };

  const expandoList = () => {
    const filterList = isExpando ? faqsCompiled : faqsCompiled.slice(0, numberOfFaqsToShow);
    return (
      <UitkExpandoList className="faqExpando">
        {filterList.map((faq, index) => (
          <Expando
            key={itemKeyHelper.next()}
            callToActionText={faq.question}
            expandedText={faq.answer}
            data-testid={`faq-${index}`}
            index={index}
            sectionId={faq.sectionId}
          />
        ))}
      </UitkExpandoList>
    );
  };

  return (
    <LazyLoad context={context}>
      <UitkSpacing padding={{ block: "six" }}>
        <div className="Faq" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
          <SchemaOrgScript structuredData={structuredData} />
          <div id={contentId} data-fm-content={contentId}>
            <UitkCard>
              <UitkCardContentSection key={itemKeyHelper.next()}>
                <UitkHeading tag="h2" size={4}>
                  {formatText("frequentlyAskedQuestions.title")}
                </UitkHeading>
              </UitkCardContentSection>
              {isExpando ? (
                <UitkExpandoPeek
                  isVisible={isExpandoVisible}
                  handleClick={handleClickAction}
                  items={displayThreshold}
                  analytics={analytics}
                  expandLabel={formatText("propertyfilter.seeMore")}
                  collapseLabel={formatText("propertyfilter.seeLess")}
                  expandA11yLabel={formatText("propertyfilter.seeMore")}
                  collapseA11yLabel={formatText("propertyfilter.seeLess")}
                  hideScrim
                >
                  {expandoList()}
                </UitkExpandoPeek>
              ) : (
                expandoList()
              )}

              {!isExpando && initialDisplayThreshold < faqs.length && (
                <UitkCardContentSection>
                  <UitkSecondaryButton isFullWidth onClick={clickHandler}>
                    {isCollapsed ? formatText("propertyfilter.seeMore") : formatText("propertyfilter.seeLess")}
                  </UitkSecondaryButton>
                </UitkCardContentSection>
              )}
            </UitkCard>
          </div>
        </div>
      </UitkSpacing>
    </LazyLoad>
  );
};

interface ExpandoProps extends React.HTMLAttributes<HTMLDivElement> {
  callToActionText: string;
  expandedText: string | undefined;
  index: number;
  sectionId: number;
}
/**
 * This is a simple expander that when clicked, will show more text
 * TODO: Replace with UITK component when it has the design we need
 * @param callToActionText - Text that the user sees regardless of expansion
 * @param expandedText - Text that the user sees once expanded
 */
const Expando = ({ callToActionText, expandedText, index, sectionId }: ExpandoProps) => {
  const track = useClickTracker();
  const [isVisible, setIsVisible] = React.useState(false);
  const visibilityStatus = isVisible ? "close" : "open";
  const trackingReferrer =
    sectionId == null ? `question.${index}.${visibilityStatus}` : `question.${index}.${sectionId}.${visibilityStatus}`;

  const callback = React.useCallback(() => {
    const flexTracking: FlexTrackingInfo = {
      moduleName: "faq",
      action: Action.CLICK,
      rfrr: trackingReferrer,
    };
    sendDelayedClickstreamTrackEvent(flexTracking, track);
  }, [track]);

  if (expandedText === undefined) {
    return null;
  }

  return (
    <UitkExpandoListItem
      isVisible={isVisible}
      expandoTitle={callToActionText}
      expandoDescription={<div className="faqText" dangerouslySetInnerHTML={{ __html: expandedText }} />}
      analytics={{
        id: trackingReferrer,
        callback,
      }}
      onToggle={() => setIsVisible((prevIsVisible) => !prevIsVisible)}
      data-testid={`faq-question-${index}`}
    />
  );
};

export const LegacyFrequentlyAskedQuestionsModule = inject("markdown", "flexModuleModelStore")(observer(LegacyFaq));

export default LegacyFrequentlyAskedQuestionsModule;
